<template>
  <div class="container-fluid px-5 py-3">
    <div class="row">
      <div class="col-md-8">
        <v-img
          class="grey darken-4"
          :src="item.thumbnail"
          :alt="item.title"
          contain
          height="300"
        ></v-img>

        <v-card-title> {{ item.title }}</v-card-title>
        <v-banner class="py-3">
          <v-avatar slot="icon" color="deep-purple accent-4" size="40">
            <v-icon icon="mdi-lock" color="white"> mdi-lock </v-icon>
          </v-avatar>

          {{ item.author.name }}

          <!-- <template v-slot:actions>
            <v-btn text color="deep-purple accent-4"> Action </v-btn>
            <v-btn text color="deep-purple accent-4"> Action </v-btn>
          </template> -->
        </v-banner>

        <p style="font-size: 1.2em">{{ item.description }}</p>

        <div v-html="item.content"></div>

        <div class="row">
          <div class="col-md-12">
            <p><strong>Tags relacionados</strong></p>
            <v-chip
              class="ma-2"
              text-color="white"
              :color="tag.color"
              v-for="(tag, index) in item.tags"
              :key="index"
            >
              {{ tag.name }}
            </v-chip>
          </div>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="heartAction()">
            <v-icon>mdi-heart</v-icon>
          </v-btn>

          <v-btn icon @click="saveAction()">
            <v-icon>mdi-bookmark</v-icon>
          </v-btn>

          <v-btn icon @click="shareAction()">
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div class="col-md-4">
        <h5 style="font-size: 1.9em; font-weight: 700">Otras noticias</h5>
        <hooper
          :vertical="true"
          style="height: 600px"
          :itemsToShow="2"
          :autoPlay="false"
          :playSpeed="3000"
          :hoverPause="true"
        >
          <slide v-for="(o_new, index) in other_news" :key="index">
            <v-card>
              <v-img
                :src="o_new.thumbnail"
                class="white--text align-end bg-events-size"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
              >
                <v-card-title
                  v-text="o_new.title"
                  style="
                    word-break: break-word;
                    font-size: 1.1em;
                    font-weight: 600;
                  "
                ></v-card-title>
              </v-img>

              <v-card-actions>
                <p style="word-break: break-word; text-transform: uppercase">
                  {{ o_new.author.name }}
                </p>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </slide>
        </hooper>
      </div>
    </div>
  </div>
</template>
<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  data() {
    return {
      loading: true,
      item: {
        author: {
          name: "",
        },
      },
      slug: "",
      other_news: [],
    };
  },
  components: {
    Hooper,
    Slide,
  },
  methods: {
    heartAction() {
      alert("Te ha gustado la noticia");
    },
    saveAction() {
      alert("Se ha guardado la noticia");
    },
    shareAction() {
      alert("Se ha compartido la noticia");
    },
    getNew: async function () {
      const { data } = await this.$axios.get(`/public/news/list/${this.slug}`);
      // const { data } = await this.$axios.get(
      //   `http://crm_ipssoma.test/api/v1/public/news/list/${this.slug}`
      // );
      this.item = data;
      console.log(data);
    },

    getOtherNews: async function () {
      const { data } = await this.$axios.get(`/public/news/random-list`);
      // const { data } = await this.$axios.get(
      //   `http://crm_ipssoma.test/api/v1/public/news/random-list`
      // );
      this.other_news = data;
    },
    incrementView: async function () {
      const { data } = await this.$axios.post(`/public/news/incrementView`, {
        slug: this.slug,
      });
      // const { data } = await this.$axios.post(
      //   `http://crm_ipssoma.test/api/v1/public/news/incrementView`,
      //   {
      //     slug: this.slug,
      //   }
      // );
      console.log(data);
      //   this.item = data;
      //   console.log(data);
    },
  },
  mounted() {
    this.getNew();
    this.incrementView();
    this.getOtherNews();
  },
  created() {
    this.slug = this.$route.params.slug;
  },
};
</script>
